import React from "react";
import BackgroundImage from "../../../component/backgroundtext/backgroundimg";
import Icons from "../../../Constants/Icon";
import Contact from "../../../component/contactus/Contact";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import BookNow from "../../../component/Button/BookNow";
import Bookfooter from '../../../component/footer/Bookfooter';
const Sauveur = [{
    bgimg: Icons.groups,
    name: "GROUPS & EVENTS",
    Rentals: "GATHER IN A PICTURESQUE LOCATION <br/> FOR AN UNPARALLELED EXPERIENCE",
    height: "100vh",
    width: "909px"
}]

const contactdata = [{
    name: "contact us",
    planning: ""
}]
const groups = [
    {
        type: "Family & friends",
        router: "/family&friends",
        imags: Icons.Family10,
        name: "Family & Friends ",
        titles: "Experience an unforgettable family reunion, birthday, anniversary or holiday celebration at Fiddler Lake Resort. We offer the ultimate venue for any group event, with or without children."

    },
    {
        type: "CORPORATE GROUPS",
        router: "/corporate ",
        imags: Icons.groups2,
        name: "Corporate Groups ",
        titles: "Host your next business meeting in a fabulous destination in the Laurentians. Our chalets are ideal for team building or breakout sessions that foster team spirit and improve collaboration."

    },
    {
        type: "Family & friends",
        router: "/weddings",
        imags: Icons.wedding123,
        name: "Weddings ",
        titles: "Celebrate your dream wedding in a uniquely charming and romantic setting. We offer you an extraordinary and customized experience. We’ll help you create memories that will be cherished for a lifetime."

    },

]
function Groups({ setLoading }) {
    const sectionimg = {
        backgroundImage: 'url(' + Icons.fiddlerchalet + ')',
        backgroundPosition: 'center top',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        minheight: "882px",

    };
    const handleLinkClick = () => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1000);

    };
    const { t, i18n } = useTranslation();
    const currentLanguageCode = i18n.language;
    const urls = {
        en: "https://company-15146508.staycation.igms.com/?lang=en",
        fr: "https://company-15146508.staycation.igms.com/?lang=fr"
    };
    const currentUrl = urls[currentLanguageCode] || urls.en;
    return (
        <div className="group_n family_fillte">
            <BackgroundImage Sauveur={Sauveur} />
            <section className="bg-skyYellow md:py-20 md:pt-1 max-[767px]:py-3" style={{ backgroundImage: 'url(' + Icons.groups5 + ')', }}>
                <div className="container">
                    {groups.map((itmes) => (

                        <div className="">
                            <div className="md:mt-20 max-[767px]:mt-5">
                                {itmes.type === "Family & friends" && (
                                    <div className="flex justify-center ">
                                        <div className="w-[60%]  max-[767px]:-ml-[11px] colleagues" >
                                            <div className="animation_img h-full">
                                                <img src={itmes.imags} className="img_animation" style={{ height: "100%", objectFit: "cover" }} />
                                            </div>
                                        </div>
                                        <div className="w-[60%]  colleagues1 md:-ml-[5%] max-[767px]:-ml-[10%] z-10 relative flex  items-center py-3">
                                            <div className="EVENTS_L" style={{ right: "40px" }}>
                                                <div className="bg-white GROUPS_ev black_shoadow md:p-[35px] max-[767px]:p-[15px]" style={{

                                                    backgroundImage: 'url(' + Icons.Birds + ')',
                                                    backgroundSize: "cover",
                                                    backgroundRepeat: "no-repeat"
                                                }}>

                                                    <h1 className="text-black text-48 Akagi-Pro-blacks    md:-mt-2 max-[767px]:-mt-2 " style={{ lineHeight: "0.9", textTransform: `${itmes.capitalize}` }}>{t(itmes.name)}</h1>
                                                    <p className="text-20 md:mt-4 AkagiPro-book">
                                                        {t(itmes.titles)}
                                                    </p>
                                                    {BookNow.map((BookNow) => (<Link to={itmes.router} onClick={handleLinkClick} className='text-white'> <button className=' bg-lightyellow text-20  mt-3 book_button p_button capitalize AkagiPro-Black text-white Learn_more' >{t("Learn more")}</button></Link>))}
                                                </div>

                                            </div>
                                        </div>

                                    </div>)}
                                {itmes.type === "CORPORATE GROUPS" && (
                                    <div className="flex justify-center md:mt-20 max-[767px]:mt-5">

                                        <div style={{ width: "60%", marginRight: "-9%" }} className=" colleagues1 z-10 relative flex  items-center py-3">
                                            <div className="EVENTS_L left_EVENTS_L" style={{ right: "-40px", paddingRight: "0px" }}>
                                                <div className="bg-white GROUPS_ev black_shoadow  relative  md:p-[35px] max-[767px]:p-[15px]" style={{

                                                    backgroundImage: 'url(' + Icons.Birds + ')',
                                                    backgroundSize: "cover",
                                                    backgroundRepeat: "no-repeat",

                                                    width: "100%"
                                                }}>

                                                    <h1 className="text-black text-48 Akagi-Pro-blacks  md:-mt-1   max-[767px]:-mt-2 text-right " style={{ lineHeight: "0.9", textTransform: `${itmes.capitalize}` }}>{t(itmes.name)}</h1>

                                                    <p className="text-20 md:mt-4  text-justify md:pl-6 AkagiPro-book">
                                                        {t(itmes.titles)}
                                                    </p>
                                                    <div className="flex justify-end w-full">
                                                        {BookNow.map((BookNow) => (<Link to={itmes.router} className='text-white' onClick={handleLinkClick} >  <button style={{ fontSize: "18px" }} className='bg-lightyellow mt-3 book_button capitalize p_button AkagiPro-Black text-white Learn_more' >{t("Learn more")}</button></Link>))}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div style={{ width: "60%" }} className=" colleagues relative max-[767px]:-right-[11px]" >
                                            <div className="animation_img h-full">
                                                <img src={itmes.imags} className="img_animation" style={{ height: "100%", objectFit: "cover" }} />
                                            </div>
                                        </div>

                                    </div>)}
                            </div>
                        </div>
                    ))}

                </div>
            </section>
            <Link to={currentUrl}>
                <div className=" bg-lightyellow button_yellow text-white  md:py-12 max-[767px]:py-5 md:mb-10 max-[767px]:mb-5" style={sectionimg}>
                    <div className="container">
                        {/* {BookNow.map((BookNow) => (<Link to={BookNow.Plan}></Link>))} */}

                        <div className="text-48 text-center  Alfarn">{t("PLAN YOUR GETAWAY NOW")}</div>
                    </div>
                </div></Link>
            <section className=" mb-5">
                <Contact setLoading={setLoading} contactdatanew={contactdata} />
            </section>

            <Bookfooter />
        </div>
    )
}
export default Groups;