import React, { useState, useEffect } from 'react';
import Bookfooter from "../../../component/footer/Bookfooter";
import CursorSlider from "../../../component/CustomSlide/chalersslider";
import Icons from "../../../Constants/Icon";
import PhoneNumberButton from "./PhoneNumbe";
import BookNow from '../../../component/Button/BookNow';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import fiddlerchalet from '../../../Assets/Icons/texture-chalet.png';
import BackgroundImage from "../../../component/backgroundtext/resortsbg";
import { useNavigate } from 'react-router-dom';
import Amenities from '../../../component/Amenities/Amenities';
import Exclusive from '../../../component/CustomSlide/Exslider';
const restaurants = [
    {
        name: "Nearby",
        title: "Stores",
        titlename: "<ul><li>Au Clair de la Lune Boutique</li><li>Tanger Factory Outlets</li><li>Desjardins Marine</li></ul>",
        Image: Icons.Restaurants,
    },
    {
        name: "Nearby",
        title: "Entertainment",
        titlename: "<ul><li>Saint-Sauveur Aquatic Park</li><li>Acro-Nature</li><li>Val-David Summer Market</li></ul>",
        Image: Icons.Restaurants1,
    },
    {
        name: "Nearby",
        title: "Spas & Massage",
        titlename: "<ul><li>Ofuro Station ZEN</li> <li>Strøm spa nordique</li><li>Saint-Sauveur Amerispa</li></ul>",
        Image: Icons.Restaurants2,
    },
    {
        name: "Nearby",
        title: " Sports",
        titlename: "<ul ><li>Golf Le Select</li><li>Golf Lachute</li><li>Golf Chantecler</li></ul>",
        Image: Icons.Restaurants3,
    },
    {
        name: "Nearby",
        title: "Restaurants",
        titlename: "<ul><li>Maestro</li><li>Gibbys</li><li>Steak Frites St-Paul</li></ul>",
        Image: Icons.Restaurants4,
    },
]
const slides = [

    {
        image: Icons.Activities1,

    },
    {
        image: Icons.Activities3,

    },
   
    {
        image: Icons.resort2,

    },
    {
        image: Icons.Activities2,

    },
 
];

const beardata = [{
    img: Icons.home,
    name: "rabbit",
    title: "These bright and comfortable chalets are perfect for retreats with friends or family. Create endless memories in this charming chalet."
    , Vectors: Icons.Vectorcagaru,
    lanermor:"/discover_chalets",
    id:"1",
    while:"53px"

},
{
    img: Icons.home1,
    name: "bear",
    title: "This model caters to guests looking to combine luxury and adventure. With its majestic windows, our Bear chalet offers breathtaking views of nature."
    , Vectors: Icons.Vectorrish,
    id:"2",
    lanermor:"/discover_chalets",
    while:"70px"

},
{
    img: Icons.homer,
    name: "deer",
    title: "Indulge in a spacious, two-storey chalet with plenty of room for family, friends, or colleagues. This elegant model has cathedral ceilings and panoramic windows, and all the extras to make your stay unforgettable."
    , Vectors: Icons.Vector8,
    id:"3",
    lanermor:"/discover_chalets",

},
{
    img: Icons.home1,
    name: "moose",
    title: "These larger chalets are the perfect retreat for corporate retreats and larger groups. Our Moose chalet is sure to amaze you with its majestic and impressive structure."
    , Vectors: Icons.Vector9,
    id:"4",
    lanermor:"/discover_chalets",
    while:"60px"

},

{
    img: Icons.home1,
    name: "elk",
    title: "Come experience the splendour of nature in this exclusive chalet. This beautifully appointed model provides luxury and plenty of space so you can host a corporate event or accommodate a large family."
    , Vectors: Icons.Vector10,
    id:"5",
    lanermor:"/discover_chalets",

},]
const sectionimg = {
    backgroundImage: 'url(' + fiddlerchalet + ')',
    backgroundPosition: 'center top',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    minheight: "882px",

};

const Holidaydata = [

    {
        birthdayimg: Icons.About3,
        familytype: "Discover the Fiddler Lake experience",
        link: "/experience",
        name: "Exclusive activities",
        titles: "Take in the natural beauty of the region with a peaceful canoe or kayak ride on the 2.8 km<sup>2</sup> lake or simply explore the acres of lush forest. Have fun in our Recreation Centre or hit some balls on the tennis courts. If relaxation is more your style, unwind in our heated indoor pool, available year-round, or our heated outdoor pool, available during the summer. Our wide range of activities is sure to make your stay unforgettable."
    }
    ,
]
function Restaurants({setitemsid}) {
    const [responsiveHeight, setResponsiveHeight] = useState(
        window.innerWidth > 768 ? "70vh" : "30vh"
    );
    useEffect(() => {
        const handleResize = () => {
            const newHeight = window.innerWidth > 768 ? "70vh" : "30vh";
            setResponsiveHeight(newHeight);
        };

        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const handleLinkClick = () => {
    };

    const Sauveur = [{
        name: "",
        height: "100vh",
        bgimg: Icons.Restaurantsbg,
        montreal: "At Fiddler Lake Resort, you can enjoy a wide range of amenities and activities just a few steps away from your chalet. Fun and adventure are guaranteed with on-site activities like canoeing, kayaking, tennis, pickleball and more!"

    }]
    const Depending = [{
        name: " ",
        height: "100vh",
        bgimg: Icons.ResortActivities,
        Rentals: "resort activities",
        width: "1200px",


    }]
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleRedirect = (item) => {
        navigate('/discover_chalets');
        setitemsid(item.id);
        setTimeout(() => {
          const div = document.getElementById(setitemsid);
          if (div) {
            div.scrollIntoView({ behavior: 'smooth' });
          }
        }, 1000); 
        console.log("fgfdg",item.id)
      };
    return (
        <div>
            <section className='ACTIVITIES_data family_fillte'>
                <BackgroundImage Depending={Depending} />
            </section>
            <section className=" bg-skyYellow md:pt-20 md:pb-20 max-[767px]:py-10">
                <div className="container">
                <p className="text-20 AkagiPro-Black text-center"><div dangerouslySetInnerHTML={{ __html: t("Enjoy the natural beauty of Fiddler Lake Resort with a peaceful canoe or kayak ride on Fiddler Lake or simply explore the acres of lush forest, a few steps away from your chalet. Our vast array of on-site activities is guaranteed to provide fun and adventure, making your stay with us unforgettable!") }} /></p>
                    {/* <p className="text-20 AkagiPro-Black text-center"><div dangerouslySetInnerHTML={{ __html: t("At Fiddler Lake Resort, you can enjoy a wide range of amenities and activities just a few steps away from your chalet. Fun and adventure are guaranteed with on-site activities like canoeing, kayaking, tennis, pickleball and more!") }} /></p> */}
                   
                    {/* <div className="flex justify-center">
                        <div className="flex  justify-between items-center md:gap-24 max-[767px]:gap-4 mt-4">
                            <div className="max-[767px]:text-center "><p className="text-lightyellow mb-0 AkagiPro-book text-30  " style={{ lineHeight: "30px" }} ><span className="text-30  underline"><Link to='/Contactus' className='text-lightyellow font-bold'>{t("Contact us")}</Link></span> {t("for exclusive discounts")}<br className="max-[767px]:hidden" />
                                {t("on select activities during your stay!")}</p></div>
                            <div className='flex justify-center mt-2'>
                                <PhoneNumberButton phoneNumber="1-800-721-0371" />
                            </div>

                        </div>

                    </div> */}
                </div>
            </section >
            <section className="Directly md:my-14 max-[767px]:my-[25px] relative">
                <Exclusive slides={slides} />
            </section>
{/* 
            <section className="md:mb-[200px] max-[767px]:mb-8 relative" >
                <Map />
            </section> */}
            {/* <section className='container relative px-8   restaurants_h '>
                <div className='flex flex-wrap justify-center  grid-cols-3 gap-4 md:-mt-10 md:-mb-[120px]'>
                    {restaurants.map((item, index) => (


                        <div style={{ width: "32%" }} className="Massage md:mb-[300px] max-[767px]:mb-3 md:relative"  >
                            <div className='animation_img md:absolute md:-mt-64'>
                                <img src={item.Image} className='w-full relative  img_animation' /></div>
                            <div className='p-7 md:h-full  max-[767px]:pl-12 text-white bg-lightyellow relative z-10  max-[767px]:-mt-20' style={{ width: "90%" }}>
                                <h3 className="AkagiPro-Black text-20  uppercase  "> {t(item.name)}</h3>
                                <p className='text-32 lg:-mt-0  max-[767px]:mt-1 md:mt-1 md:mb-1  lg:-mb-0  max-[767px]:mb-1 AkagiPro-Black '>{t(item.title)}</p>
                                <div className='text-20'>
                                    <div dangerouslySetInnerHTML={{ __html: t(item.titlename) }} /></div>
                                {BookNow.map((BookNow) => (<Link to='/discover_chalets' onClick={handleLinkClick} className='text-white' style={{ display: "none" }}>      <h3 className="AkagiPro-Black md:ml-4 text-20 md:-mb-2 capitalize md:mt-9 max-[767px]:mt-5 flex items-baseline gap-2 cursor-pointer ">{t("view more")} <img src={Icons.whitetir} className="max-[767px]:w-6" /></h3></Link>))}

                            </div>

                        </div>
                    ))} </div>
            </section > */}
            <section className='w-full bg-skyYellow pt-10 pb-14 md:my-16   max-[767px]:mt-[25px] max-[767px]:mb-[25px] Amenities_h' style={sectionimg}>

                <Amenities />
            </section>
            <section className="bg-lightyellow md:py-20 max-[767px]:pb-12 max-[767px]:py-3" style={{ backgroundImage: 'url(' + Icons.groups5 + ')', backgroundSize: "100%",display:"none" }}>
                <div className="container">
                    {Holidaydata.map((items) => (
                        <div >
                            <div> </div>
                            {items.familytype == "Discover the Fiddler Lake experience" && (
                                <div className="grid md:gap-4 max-[767px]:gap-2 grid-cols-2">
                                    <div className="flex justify-end items-center max-[767px]:py-8  max-[767px]:pb-12">
                                        <div className="bg-white  relative  black_shoadow md:p-8 max-[767px]:p-4 max-[767px]:pb-10 max-[767px]:-mr-[10px] md:-mr-[16px]" style={{ maxWidth: "569px" }}>
                                            <h2 className="text-right text-32 Akagi-Pro-blacks  md:mt-6 max-[767px]:mt-2 capitalize ">  {t(items.name)}</h2>
                                            <p className=" text-justify text-20 text-gray AkagiPro-book" dangerouslySetInnerHTML={{ __html: t(items.titles) }} />
                                            <Link to={items.link} onClick={handleLinkClick} >  <button className="bg-black py-2 black_shoadow uppercase  an_button  max-[767px]:px-3 md:px-8  absolute -bottom-5 right-0  text-white text-20 AkagiPro-Black ">
                                                {t(items.familytype)}</button></Link>
                                        </div>
                                    </div>
                                    <div className="border-l-2 border-white md:pl-5 pl-2 max-[767px]:py-5">
                                        <div className="max-[767px]:h-full  max-[767px]:h-full  object-cover animation_img">
                                            <img src={items.birthdayimg} className="max-[767px]:h-full max-[767px]:h-full  object-cover img_animation" />
                                        </div>
                                    </div>

                                </div>

                            )}
                            {items.familytype == "Explore the region’s activities and restaurants" && (
                                <div className="grid md:gap-4 max-[767px]:gap-2 grid-cols-2">
                                    <div className="max-[767px]:pt-5 max-[767px]:pb-5">
                                        <div className=" animation_img max-[767px]:h-full max-[767px]:w-full object-cover">
                                            <img src={items.birthdayimg} className="max-[767px]:h-full max-[767px]:w-full object-cover img_animation" /></div>
                                    </div>
                                    <div className="border-l-2 md:pt-16  border-white md:mt-0 flex justify-start items-center " >
                                        <div className="bg-white md:-mt-20    max-[767px]:mt-8 max-[767px]:mb-12    relative  black_shoadow md:p-8 max-[767px]:p-4 max-[767px]:pb-8 md:pb-16 max-[386px]:pb-12" style={{ maxWidth: "569px" }}>
                                            <h2 className="text-32 Akagi-Pro-blacks ">  {t(items.name)}</h2>
                                            <p className="text-20 AkagiPro-book text-justify text-gray ">{t(items.titles)}</p>
                                            <Link to={items.link} onClick={handleLinkClick} >  <button className="bg-black uppercase an_button py-2 black_shoadow   max-[767px]:px-3 md:px-8 absolute -bottom-5 left-0  text-white text-20 AkagiPro-Black ">
                                                {t(items.familytype)}</button></Link>
                                        </div>
                                    </div>

                                </div>

                            )}


                        </div>
                    ))}
                </div>
            </section>
            <section className="text-center container  px-8 md:mt-4 max-[767px]:mt-1">
                {/* <div className="flex justify-center w-full md:mt-12 max-[767px]:mt-10 md:-mb-6 max-[767px]:-mb-3">
                    <div className='flex items-center max-[767px]:gap-3 md:gap-8 md:mb-3 line-des1'>
                        <div className='md:w-16 max-[767px]:w-6  bg-black  items-center mb-2 line-des' style={{ height: "1px" }}></div>
                        <h3 className="AkagiPro-Black text-32  ">{t("FIDDLER LAKE RESORT")}</h3>
                    </div>
                </div> */}
               <h1 className="text-black mt-2 md:mb-5  text-64 Alfarn">{t("Discover our chalets")}</h1>
                <p className="text-20 AkagiPro-book md:mt-4">{t("Escape to one of our luxurious chalets, each boasting floor-to-ceiling windows, a stone fireplace, and an outdoor patio equipped with a BBQ.")}</p>
                <p className="text-20 AkagiPro-book md:mt-4" dangerouslySetInnerHTML={{ __html: t("Our models range in size from three to six rooms, and depending on the chalet chosen, additional features may include a three- or four-season veranda,<br />private hot tub, private sauna, pool table, or even a private pool.") }} />
                <p className="text-20 AkagiPro-book md:mt-4">{t("Read each model’s description for specific features and detailed information.")}</p>

            </section>
            <section className='relative   w-full md:mt-8  max-[767px]:mt-5 md:mb-0 max-[767px]:mb-0 md:pl-14 pl-0 overflow-hidden pb-[150px] ' >
                <div>
                    <CursorSlider beardata={beardata} handleRedirect={handleRedirect} />
                </div>
            </section>
            <Bookfooter />
        </div >
    )
}
export default Restaurants;