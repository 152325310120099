const Icons = {
    fiddler: require("../Assets/Icons/fidder.png"),
    fiddler1: require("../Assets/Icons/fidder1.png"),
    fiddler2: require("../Assets/Icons/fidder2.png"),
    fiddler3: require("../Assets/Icons/fidder3.png"),
    fiddler4: require("../Assets/Icons/fidder4.png"),
    fiddler5: require("../Assets/Icons/fidder5.png"),
    fiddler6: require("../Assets/Icons/fidder6.png"),
    fiddler7: require("../Assets/Icons/fidder7.png"),
    fiddler8: require("../Assets/Icons/fidder8.png"),
    fiddler9: require("../Assets/Icons/fidder9.png"),
    fiddler10: require("../Assets/Icons/fidder10.png"),
    fiddler11: require("../Assets/Icons/fidder11.png"),
    fiddler12: require("../Assets/Icons/fidder12.png"),
    fiddler13: require("../Assets/Icons/fidder13.png"),
    lacfiddler: require("../Assets/Icons/lacfiddler.png"),
    Rectangle1: require("../Assets/Icons/Rectangle1.jpg"),
    Rectangle4: require("../Assets/Icons/Rectangle4.png"),
    Vectorcagaru: require("../Assets/Icons/Vectorcagaru.png"),
    Vectorrish: require("../Assets/Icons/Vectorrish.png"),
    Tira: require("../Assets/Icons/Tira.png"),
    Line: require("../Assets/Icons/Line.png"),
    evenements1: require("../Assets/Icons/Foranyoccasion/evenements1.png"),
    evenements2: require("../Assets/Icons/Foranyoccasion/evenements2.png"),
    evenements3: require("../Assets/Icons/Foranyoccasion/evenements3.png"),
    evenements4: require("../Assets/Icons/Foranyoccasion/evenements4.png"),
    evenements5: require("../Assets/Icons/Foranyoccasion/evenements5.png"),
    family: require("../Assets/Icons/Foranyoccasion/family.png"),
    family1: require("../Assets/Icons/Foranyoccasion/family1.png"),
    family2: require("../Assets/Icons/Foranyoccasion/family2.png"),
    Facebook: require("../Assets/Icons/Facebook.png"),
    youtube: require("../Assets/Icons/youtube.png"),
    twitter: require("../Assets/Icons/twitter.png"),
    instagram: require("../Assets/Icons/instagram.png"),
    linkedin: require("../Assets/Icons/linkedin.png"),
    traveler: require("../Assets/Icons/traveler.png"),
    traveler1: require("../Assets/Icons/traveler1.png"),
    traveler2: require("../Assets/Icons/traveler2.png"),
    arrowdown: require("../Assets/Icons/arrowdown.png"),
    whitetir: require("../Assets/Icons/whitetir.png"),
    Region: require("../Assets/Icons/Experience/Region.png"),
    Region1: require("../Assets/Icons/Experience/Region1.png"),
    Region2: require("../Assets/Icons/Experience/Region2.png"),
    Region3: require("../Assets/Icons/Experience/Region3.png"),
    groups: require("../Assets/Icons/groups/groups.jpg"),
    groups5: require("../Assets/Icons/groups/groups5.png"),
    groups2: require("../Assets/Icons/groups/groups2.png"),
    groups4: require("../Assets/Icons/groups/groups4.png"),
    home5: require("../Assets/Icons/contact/home1.png"),
    home2: require("../Assets/Icons/contact/home2.png"),
    home3: require("../Assets/Icons/contact/home3.png"),
    home4: require("../Assets/Icons/contact/home4.png"),
    arror_down: require("../Assets/Icons/contact/arror_down.png"),
    Restaurantsbg: require("../Assets/Icons/Restaurants/Restaurantsbg.png"),
    Restaurants: require("../Assets/Icons/Restaurants/Restaurants.jpg"),
    Restaurants1: require("../Assets/Icons/Restaurants/Restaurants1.jpg"),
    Restaurants2: require("../Assets/Icons/Restaurants/Restaurants2.jpg"),
    Restaurants3: require("../Assets/Icons/Restaurants/Restaurants3.jpg"),
    Restaurants4: require("../Assets/Icons/Restaurants/Restaurants4.png"),
    Family10: require("../Assets/Icons/Family Friend/Family10.jpg"),
    Family: require("../Assets/Icons/Family Friend/Family3.jpg"),
    Family2: require("../Assets/Icons/Family Friend/Family2.png"),
    Family5: require("../Assets/Icons/Family Friend/Family5.png"),
    Family6: require("../Assets/Icons/Family Friend/Family6.jpg"),
    Family7: require("../Assets/Icons/Family Friend/fiddler11.jpg"),
    Family8: require("../Assets/Icons/Family Friend/Family8.jpg"),

    Family9: require("../Assets/Icons/Family Friend/Family9.png"),
    Birds: require("../Assets/Icons/Family Friend/Birds.png"),
    Weddingsbg: require("../Assets/Icons/Weddings/Weddingsbg.jpg"),
    Weddings: require("../Assets/Icons/Weddings/Weddings.png"),
    Weddings1: require("../Assets/Icons/Weddings/Weddings1.png"),
    Weddings2: require("../Assets/Icons/Weddings/Wedding2.jpg"),
    Weddings3: require("../Assets/Icons/Weddings/Weddings3.png"),
    Weddings4: require("../Assets/Icons/Weddings/Weddings4.jpg"),
    Weddings5: require("../Assets/Icons/Weddings/Weddings5.png"),
    Resort111: require("../Assets/Icons/RESORT/Resort.png"),
    Resort1: require("../Assets/Icons/RESORT/Resort1.png"),
    Resort2: require("../Assets/Icons/RESORT/Resort2.jpg"),
    Resort3: require("../Assets/Icons/RESORT/Resort3.png"),
    Resort4: require("../Assets/Icons/RESORT/Resort4.png"),
    fiddlerchalet: require("../Assets/Icons/texture-chalet.png"),
    Resort55: require("../Assets/Icons/RESORT/Resort5.jpg"),
    Resort6: require("../Assets/Icons/RESORT/Resort6.jpg"),
    Resort7: require("../Assets/Icons/RESORT/Resort7.jpg"),
    Resort9: require("../Assets/Icons/RESORT/Resort9.png"),
    Resort51: require("../Assets/Icons/RESORT/Resort51.png"),
    Resort14: require("../Assets/Icons/RESORT/Resort14.png"),
    Resort16: require("../Assets/Icons/RESORT/Resort16.png"),
    Resort15: require("../Assets/Icons/RESORT/Resort15.png"),
    Resort17: require("../Assets/Icons/RESORT/Resort17.png"),
    Resort11: require("../Assets/Icons/RESORT/Resort111.png"),
    Resort12: require("../Assets/Icons/RESORT/Resort13.jpg"),
    Resort13: require("../Assets/Icons/RESORT/Resort13.png"),
    menu: require("../Assets/Icons/menu.png"),
    rabbit7: require("../Assets/Icons/Discover/rabbit7.png"),
    Vector8: require("../Assets/Icons/Vector8.png"),
    Vector9: require("../Assets/Icons/Vector9.png"),
    Vector10: require("../Assets/Icons/Vector10.png"),
    rsevenements: require("../Assets/Icons/rsevenements.png"),
    family_: require("../Assets/Icons/Foranyoccasion/family_.png"),
    corpo1: require("../Assets/Icons/Family Friend/corpo1.png"),
    Group: require("../Assets/Icons/Group.png"),

    // home page images start
    homer: require("../Assets/Icons/homer.png"),
    Rabbit: require("../Assets/imgas/Rabbit.jpg"),
    Bear: require("../Assets/imgas/Bear.jpg"),
    Deer: require("../Assets/imgas/Deer.jpg"),
    Moose: require("../Assets/imgas/Moose.jpg"),
    Elk: require("../Assets/imgas/Elk.jpg"),
    HomeServicesConcierge: require("../Assets/imgas/home/HomeServicesConcierge.jpg"),
    Christmas: require("../Assets/imgas/home/Christmas.jpg"),

    // home page images start
    LocalActivities: require("../Assets/Icons/Restaurants/LocalActivities.jpg"),
    resort2: require("../Assets/Icons/Experience/resort2.jpg"),
    resort3: require("../Assets/Icons/Experience/resort3.jpg"),
    resort4: require("../Assets/Icons/Experience/resort4.jpg"),
    Resort555: require("../Assets/Icons/RESORT/Resort555.jpg"),
    CorporateBanner: require("../Assets/Icons/RESORT/CorporateBanner.jpg"),
    home11: require("../Assets/Icons/home/home1.jpg"),
    home12: require("../Assets/Icons/home/home2.jpg"),
    home13: require("../Assets/Icons/home/home3.jpg"),
    Activitiexpe: require("../Assets/Icons/Experience/Activitiexpe.jpg"),
    Activitiexpe1: require("../Assets/Icons/Experience/Activitiexpe1.jpg"),
    Activitiexpe2: require("../Assets/Icons/Experience/Activitiexpe2.jpg"),
    Activitiexpe3: require("../Assets/Icons/Experience/Activitiexpe3.jpeg"),
    Union: require("../Assets/Icons/Discover/vs/Union.png"),
    Union1: require("../Assets/Icons/Discover/vs/Union1.png"),
    Union2: require("../Assets/Icons/Discover/vs/Union2.png"),
    Union3: require("../Assets/Icons/Discover/vs/Union3.png"),
    Union4: require("../Assets/Icons/Discover/vs/Union4.png"),
    wedding123: require("../Assets/Icons/Weddings/wedding123.jpg"),
    personalizedevents1: require("../Assets/Icons/Family Friend/personalizedevents1.jpeg"),
    personalizedevents2: require("../Assets/Icons/Family Friend/personalizedevents2.jpeg"),
    personalizedevents3: require("../Assets/Icons/Family Friend/personalizedevents3.jpeg"),
    personalizedevents4: require("../Assets/Icons/Family Friend/personalizedevents4.jpeg"),
    carmode1_: require("../Assets/Icons/vs/carmode1.jpg"),
    map_final: require("../Assets/Icons/vs/map-final.jpg"),
    Gibbys: require("../Assets/imgas/Gibbys.jpg"),
    Bookyourstay: require("../Assets/imgas/Bookyourstay.jpg"),
    // Resort activities page imags
    ResortActivities: require("../Assets/imgas/ResortActivities.jpg"),
    Activities1: require("../Assets/imgas/ResortActivities/Activities1.jpg"),
    Activities2: require("../Assets/imgas/ResortActivities/Activities2.jpg"),
    Activities3: require("../Assets/imgas/ResortActivities/Activities3.jpg"),
    // Resort activities page imags end

    // Resort services  page imags 
    ResortServicensBanner:require("../Assets/imgas/ResortServicensBanner.jpg"),
    ServicensConcierge:require("../Assets/imgas/ServicensConcierge.jpg"),
    BabyGear:require("../Assets/imgas/BabyGear.jpg"),
    GibbysSauveurPic: require("../Assets/imgas/GibbysSauveurPic.jpg"),
    ChaletsBanner: require("../Assets/imgas/ChaletsBanner.jpg"),
    CorporateGroups: require("../Assets/imgas/CorporateGroups.jpg"),
    Discover44: require("../Assets/imgas/Discover44.jpg"),
    coporet_ic: require("../Assets/imgas/coporet_ic.jpg"),
    weddings_IC: require("../Assets/imgas/weddings_IC.jpg"),
    GeneralInformation: require("../Assets/imgas/GeneralInformation.jpg"),
    Amenities: require("../Assets/imgas/Amenities.jpg"),
    website:require("../Assets/website.mp4"),
};
export default Icons;
