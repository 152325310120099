import React from "react";
import Icons from "../../Constants/Icon";
import { useTranslation } from 'react-i18next';
const fidderdata = [{
    name: "2.8 km<sup>2</sup> Lake",
    img: Icons.fiddler,
    
},
{
    name: "2 heated pools (indoor/outdoor)",
    img: Icons.fiddler1,
},
{
    name: "Canoes and kayaks",
    img: Icons.fiddler2,
},
// {
//     name: "Dual kayaks",
//     img: Icons.fiddler3,
// },
{
    name: "Paddleboards",
    img: Icons.fiddler4,
},
{
    name: "Playgrounds",
    img: Icons.fiddler5,
},
// {
//     name: "Horseshoe pits, bean-bag toss areas, etc.",
//     img: Icons.fiddler6,
// },
{
    name: "Hiking trails",
    img: Icons.fiddler10,
},
{
    name: "Snowshoes",
    img: Icons.fiddler11,
},
{
    name: "Skating rink",
    img: Icons.fiddler8,
}
    ,
{
    name: "Tennis court",
    img: Icons.fiddler12,
},
{
    name: "2 Pickleball courts",
    img: Icons.fiddler13,
}
    ,
{
    name: "Badminton",
    img: Icons.fiddler7,
},
{
    name: "Basketball",
    img: Icons.fiddler9,
},
]
function Amenities(color) {
    const { t } = useTranslation();
return(
    <div>
 <div className='container  text-center'>
                    <h1  style={color} className="text-white text-64 Alfarn md:my-7 md:mb-24 capitalize">{t("Amenities and Activities")}</h1>
                    <div className="flex flex-wrap items-center justify-center md:gap-20 gap-12 mt-14">
                        {fidderdata.map((item, index) => (
                            <div className='flex flex-col justify-top items-center gap-7 w-16  md:w-48  xl:w-[15rem] m-auto Canoe_animation'>

                                <img src={item.img} className='w-14 h-14 object-contain object-bottom' />
                                <p className="text-20 AkagiPro-book text-white min-h-[50px] " dangerouslySetInnerHTML={{ __html: t(item.name) }} />
                            </div>

                        ))}</div>
                </div>
    </div>
)

}
export default Amenities;











 